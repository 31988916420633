import Vue from 'vue'
import VueScrollTo from "vue-scrollto"

Vue.use( VueScrollTo )
Vue.config.productionTip = false

import Index from "./Index.vue"

const app = new Vue({ render: h => h(Index) }).$mount('#app')

