<template>
	<div id="index" ref="top" :style="'--bands-angle: ' + bandsAngle">
		<div class="back-home">
			<div class="navbar row">
				<a class="sections col-md-2" href="#apropos">
					A propos
				</a>
				<a class="sections col-md-2" href="#motor">
					Services
				</a> 
				<div class="sections logo col-md-4">
					<img class="logo" src="../../../img/logo3.png"/>
				</div>
				<a class="sections col-md-2"  href="#photos">
					Galerie
				</a> 
				<a class="sections col-md-2"  href="#contact">
					Contact
				</a> 
			</div>
			<div class="fond"></div>
			<h1 class="title">
				Spécialiste de véhicules américains
			</h1>
			<div class="title subtitle">
			depuis 1989
			</div>
		</div>
		<div  id="motor" class="motor">
			<img class="icon" data-aos="fade-up" data-aos-delay="100" data-aos-duration="400" src="../../../img/motor.png"/>
		</div>
		<div class="expert row">
			<div class="col-md-6" data-aos-delay="100" data-aos-duration="200" data-aos="fade-right">
				<h2 class="titles">
					Prestations
				</h2>
				<ul class="text">
				 <li><h3>Diagnostic</h3></li>
				 <li><h3>Réparation de véhicules</h3></li>
				 <li><h3>Entretien de véhicules</h3></li>
				 <li><h3>Équipement</h3></li>
				 <li><h3>Stock</h3></li>
				 <li><h3>Vente de pièces détachées</h3></li>
				 <li><h3>Vente de véhicules d’occasion</h3></li>
				</ul>
			</div>
			<div class="col-md-6" data-aos-delay="100" data-aos-duration="200" data-aos="fade-left">
				<h2 class="titles">
					Equipement complet
				</h2>
				<ul class="text">
				 <li><h3>Station climatisation</h3></li>
				 <li><h3>Connexion GM</h3></li>
				 <li><h3>Outil programmation capteurs pression pneus</h3></li>
				 <li><h3>Manuels d’atelier</h3></li>
				 <li><h3>Documentation détaillée des véhicules américains des dernières années</h3></li>
				 <li><h3>Outils informatiques récents</h3></li>
				</ul>
			</div>
		</div>
		<div class="back-options">
			<div class="options">
				<div class="superbar row">
						<a class="col-md-4" href="#photos" style="text-align: right; margin-right: 2rem;">Galerie</a>
						<a class="col-md-4" href="#contact">Contact</a>
						<a class="col-md-4" href="#work" style="text-align: left; margin-left: 2rem;">Article</a>
					</div>
				<div>
			</div>
		</div>
	</div>
	<div>
		<div id="apropos" class="apropos row">
			<div class="col-md-3">
				<img class="vk" src="../../../img/vic.jpg"/>
			</div>
			<div class="col-md-1">
				<div class="band"></div>
			</div>
			<div class="col-md-6"  data-aos="fade-up-left" data-aos-delay="100" data-aos-duration="400">
				<h2 class="iam">
					je suis Victor FERNANDES, patron de Espace VF Automobiles.
				</h2>
				<div class="text">
					Je travaille sur les voitures américaine depuis Septembre 1989 et possède notamment la qualification niveau OR chez General Motors Training.
				</div>
				<div class="text">
					La confiance est une valeur primordiale dans mon travail. Il me tient à cœur de réaliser un travail de qualité, dans les meilleurs délais et cela, en toute transparence avec le client.
				</div>
				<div class="text">
					Anciennement chef d’atelier de Jean-Charles automobiles, j’ai décidé de reprendre la main lors de l’arrêt de l’activité de l’entreprise en créant Espace VF Automobiles.
					Cela fait désormais plusieurs années que je m’applique à entretenir ces américaines que je connais déjà fort bien. J’aimerais désormais ajouter une corde à mon arc en réalisant de la vente de véhicules d’occasions.
					<img class="sign" src="../../../img/sign.png"/>
				</div>
			</div>
		</div>
		<div class="empty">
			<div class="mark">
				Nos marques
			</div>
			<carousel :autoplay="true" :perPage="2" :perPageCustom="[[768, 5]]" :loop="true" :scrollPerPage="false">
				<slide>
					<div class="back-mark">
						<img class="marks" src="../../../img/logocar1.png"/>
						<h2 class="text">Cadillac</h2>
					</div>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-chevrolet.png"/>
					<h2 class="text">Chevrolet</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-corvette.png"/>
					<h2 class="text">Corvette</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-pontiac.png"/>
					<h2 class="text">Pontiac</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-gm.png"/>
					<h2 class="text">General Motors</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-dodge.png"/>
					<h2 class="text">Dodge</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-ford.png"/>
					<h2 class="text">Ford</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-jeep.png"/>
					<h2 class="text">Jeep</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-buick.png"/>
					<h2 class="text">Buick</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-oldsmobile.png"/>
					<h2 class="text">Oldsmobile</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-gmc.png"/>
					<h2 class="text">GMC</h2>
				</slide>
				<slide>
					<img class="marks" src="../../../img/ic-lincoln.png"/>
					<h2 class="text">Licoln</h2>
				</slide>
			</carousel>
				</div>
			</div>
			<div id="work" class="work">
				<div class="corvette">
					<div class="block-mark">
						<div class="mark">
							Ils parlent de nous !
						</div>
						<div class="subtext" data-aos="fade-down" data-aos-duration="1000">
							« Cela ne se déroulera plus à Paris, mais à Argenteuil (95), au nord ouest de la capitale : "Je vais récupérer le fichier clients" explique ainsi Victor Fernandes, "à moi de me faire connaître ! Si je parviens à récupérer 30% de l'activité de Jean-Charles automobiles, ce sera déjà bien ! L'atelier était rempli jusqu'au dernier jour...".<br/>
							Dans un premier temps, M.Fernandes s'appliquera à entretenir ces américaines qu'il connait déjà fort bien.
							Mais à moyen terme, la vente de véhicules d'occasion pourrait aussi faire partie des projets.» 
						</div>
						<div class="argus">
							L'Argus - N°4463, Arnaud MURATI publié le 28/05/2013.
						</div>
					</div>
					<!--img class="img" src="../../../img/chevrolet.png"/-->
				</div>
			</div>
			<div class="row mag">
				<div class="col-md-6">
					<img class="mgz" src="../../../img/mgz2.jpg"/>
					<img class="mgz" src="../../../img/mzg.jpg"/>
				</div>
				<div class="col-md-6">
					<div class="overflow d-block">
						<div class="title-art" data-aos="fade-left" data-aos-duration="1000">
							<img class="mgz" src="../../../img/Logoargus.png"/>
						</div>
						<div class="subtext" data-aos="fade-left" data-aos-duration="1000">
							« Le show biz, les gros V8 ou les Corvette de 500ch auraient pu lui tourner la tête. Mais à 44 ans, Victor Fernandes, le patron de VF Automobiles, l'a bien sur les épaules. Il n'aura même pas attendu une semaine entre la fermeture de Jean-Charles automobiles, affaire située à Paris jusqu'en 2013, et l'ouverture de VF automobiles à Argenteuil (95). Car il y avait urgence. Jean-Charles, l'un des principaux distributeurs de voitures américaines en Ile-de-France, venait de décéder et laissait derrière lui un généreux fichier de clients. Mais aussi des dettes, lesquelles ont imposé un départ en banlieue à celui qui était jusqu'alors chef d'atelier chez Jean-Charles automobiles [...] »
							<div class="argus">
								L'Argus - N°4463, Arnaud MURATI publié le 28/05/2015.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="photos" class="photos">
				<div class="mark">
					Notre Galerie
				</div>
				<carousel :autoplay="false" :perPage="1"  :loop="true">
					<slide>
						<div class="row">
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery1.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery2.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery3.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery4.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery5.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery6.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery7.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery8.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery9.jpg"/>
								</div>
							</div>
						</div>
					</slide>
					<slide>
						<div class="row">
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery10.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery11.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery12.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery13.png"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery14.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery15.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery16.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery17.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery18.jpg"/>
								</div>
							</div>
						</div>
					</slide>
					<slide>
						<div class="row">
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery19.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery20.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery21.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery22.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery23.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery24.jpg"/>
								</div>
							</div>
							<div class="col-md-4">
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery25.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery26.jpg"/>
								</div>
								<div class="cadre">
									<VLazyImage class="photo" src="/gallery/gallery27.png"/>
								</div>
							</div>
						</div>
					</slide>
				</carousel>
				<!--div class="seemore">
					Voir plus
				</div-->
			</div>
			<div class="part2">
			<div class="tech"></div>
				<div id="contact" class="row">
					<div class="col-md-3" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
						<div class="contact">
							Contact
						</div>
						<div class="text">
							<div class="title-text">Adresse</div> 
							<a target="_blank" href="https://www.google.fr/maps/place/2+Rue+d'Alsace,+95100+Argenteuil/@48.9617773,2.2688315,17z/"  class="subtitle">
								2 rue d'Alsace	<br/>
								95100 Argenteuil	<br/><br/>
							</a>
							<div class="title-text">Téléphone</div>
							<a  href="tel:+33628596069" class="subtitle">
								06 28 59 60 69	<br/><br/>
							</a>
							<div class="title-text">Adresse électronique</div>
							<a href="mailto:victor@espacevf.fr" class="subtitle">
								victor@espacevf.fr <br/> <br/>
							</a>
							<div class="title-text">Horaires</div>
							<a class="subtitle">
							</a>
							Du lundi au vendredi	<br/>
							8h30-12h30 / 14h-18h	<br/>
					</div>
				</div>
				<div class="col-md-9">
					<div class="map">
						<l-map :zoom="13" :center="[48.961442,2.271159]" :ptions="{}">
							<l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
							<l-marker :lat-lng="[48.961442,2.271159]">
							</l-marker>
						</l-map>
					</div>
				</div>
			</div>
			<div id="footer">
				<a href="https://www.facebook.com/espacevfauto.victor">
					<i class="fab fa-facebook-square"></i>
				</a>
				<div>
					<a href="/terms.html">Mentions Légales du site</a>
				</div>
				<div id="bot-footer">
					<p>Copyright © 2020, tous droits reservés.</p>
				</div>
			</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';
	import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
	import VLazyImage from 'v-lazy-image'
	import 'leaflet/dist/leaflet.css'
	export default {
		components: {
			Carousel,
			Slide,
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
			VLazyImage,
		},
		mounted() {
			delete L.Icon.Default.prototype._getIconUrl
			L.Icon.Default.mergeOptions({
				iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
				iconUrl: require('leaflet/dist/images/marker-icon.png'),
				shadowUrl: require('leaflet/dist/images/marker-shadow.png')
			})
			window.addEventListener( "resize", () => {
				this.updateBands()
			})
		},
		methods: {
			updateBands() {
				console.log(window.innerWidth, Math.atan( 64 / window.innerWidth ))
				const angle = Math.min( 2, Math.atan( 64 / window.innerWidth ) * 180 / Math.PI )
				this.bandsAngle = angle + "deg"
			}
		},
		data() {
			return {
				bandsAngle: "0deg",
			}
		}
	}
</script>

<style lang="scss" src="../../css/pages/index.scss"></style>
